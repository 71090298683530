import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { escapeHtml, getExamStatusFilter, ReferralActions } from '../../utils/Utils';
import moment from 'moment'
import Footer from '../Footer';
import { Toast } from 'primereact/toast';
import { ReferralService } from './ReferralService';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea'
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Button } from 'primereact/button';
import DisclaimerTop from '../disclaimer_components/DisclaimerTop';
import DisclaimerBottom from '../disclaimer_components/DisclaimerBottom';
import { useUploadQueueContext } from "./upload/UploadQueueContextApi";
import { Dialog } from 'primereact/dialog';
import UploadForm from "./upload/UploadForm";
import { UploadStatusEnum } from "./upload/UploadConfig";
import { useBroadcastChannel } from '../../context/BroadcastChannelContext';

function ExamDetails({ apiConfig, setSelectedTab }) {
    const { message } = useBroadcastChannel();
    const TIME_INTERVAL_API_CALL_IN_MIN = 5

    const initialValue = {
        status: 'PENDING',
        followUpDate: null,
        examNotes : ''
    }

    const TEXT = {
        ONLY_ONE_STUDY_CAN_UPLOAD : "Only one study can be uploaded at a time.",
        FAILED_PLEASE_TRY_AGAIN: "Failed to upload Study. Please try again.",
        UPLOAD_STUDY_DICOM_REPORT : "Upload study DICOM and Report.",
        VIEW                      : 'VIEW',
        CLOSE                     : 'CLOSE',
        SUCCESS                   : 'SUCCESS'
    }
    
    const { exam_id } = useParams()
    const [examDetails, setExamDetails] = useState({});
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const navigate = useNavigate();
    const [examFormData, setExamFormData] = useState(initialValue);
    const examStatuses = getExamStatusFilter(false, apiConfig?.isAmbra);
    const [updateInProgress, setUpdateResetInProgress] = useState(false);
    const examDetailsDiv = useRef(null)
    const [divHeight, setDivHeight] = useState('0px')
    const DEFAULT_MESSAGE = 'Information not provided';
    const [actionDialog, setActionDialog] = useState(false);
    const [uploadFormData, setUploadFormData] = useState()
    const [currentExam, setCurrentExam] = useState(null);
    const { uploadContextState }  = useUploadQueueContext()

    const getStudyData = () => {
        ReferralService.getExamDetails(exam_id).then((data) => {
            if (data?.data) {
                setLoading(false)
                setExamDetails(data.data)
                let followupDate = getFollowupDate(data.data);                    
                setExamFormData({ ...examFormData, status: getStatus(data.data), 
                    followUpDate: followupDate, 
                    initialFollowupDate : followupDate,
                    examNotes : data?.data?.exam_notes
                })
            } else {
                displayToastMessage('error', 'Invalid ExamID or Access Denied!');
                setTimeout(function () {
                    navigate("/")
                }, 1000)
            }
        });
    }
    useEffect(() => {
        if (exam_id) {
            getStudyData()
        }
    }, [exam_id])
    useEffect(() => {
        
            calcTableHeight()
        
    }, [examDetails])
    const getUploadStatus = (examDetails, currentQueue) => {        
        let statuses = apiConfig?.report_in_dicom ? [examDetails?.['DICOM Upload Status']] : [examDetails?.['DICOM Upload Status'], examDetails?.['Report Upload Status']]
        if (currentQueue)
            statuses = currentQueue?.fileInfo?.map(res => res['status'])
        
        let status = UploadStatusEnum.INPROGRESS
        if (statuses.every(stat => !stat))
            status = ""
        else if (statuses.every(stat => stat === UploadStatusEnum.COMPLETED))
            status = UploadStatusEnum.COMPLETED
        else if (statuses.every(stat => stat === UploadStatusEnum.QUEUED))
            status = UploadStatusEnum.QUEUED
        else if (statuses.includes(UploadStatusEnum.FAILED))
            status = UploadStatusEnum.FAILED
        else if(statuses.includes(UploadStatusEnum.INPROGRESS))
            status = UploadStatusEnum.INPROGRESS
        else if(statuses.includes(UploadStatusEnum.STARTED))
            status = UploadStatusEnum.STARTED
        return status
    }
    const getUploadQueue = (examid) => (uploadContextState?.queue ? uploadContextState?.queue.find(({ id }) => id === examid) : null)
    const currentQueue = getUploadQueue(examDetails?.exam_id);
    const uploadStatus = getUploadStatus(examDetails, currentQueue)
    useEffect(()=>{
        if (uploadStatus === UploadStatusEnum.COMPLETED){
            getStudyData()
        }
    }, [uploadStatus])
    const getStatus = (data) => {
        if (data?.['Prov Status']) {
            return data['Prov Status'];
        }

        return initialValue.status;
    }

    const getFollowupDate = (data) => {
        if (data?.['Follow-up Date']) {
            return getDate(data?.['Follow-up Date'])
        }
        return null;
    }

    const getDate = (dateValue) => {
        let  dateVal = moment.utc(dateValue);
        return new Date(dateVal.year(), dateVal.month(), dateVal.date())
    }

    const displayToastMessage = (severity, message) => {
        let errorClass = severity === 'error' ? 'error-toast' : 'success-toast';
        let toastMessage = {
            severity: severity,
            life: 3000,
            summary: message,
            className: errorClass
        }
        toast.current.show(toastMessage);
    };


    const loadDateTemplate = (date) => {
        if (date) {
            return moment.utc(date).format('MMMM DD, YYYY');
        }
        return ''
    }
    const getData = (key) => {
        return examDetails?.[key]?.trim() ? examDetails[key] : DEFAULT_MESSAGE;
    }

    const authorizationData = [
        { title: 'Insurance Carrier', value: getData('Insurance Carrier') },
        { title: 'Member Insurance ID', value: getData('Member Insurance ID') },
        { title: 'Authorization ID', value: getData('Auth Number') },
        { title: 'Authorization Date', value: examDetails?.['Auth Date'] ? loadDateTemplate(examDetails?.['Auth Date']) : getData('Auth Date') },
        { title: 'Expiration Date', value: examDetails?.['Auth ExpDate'] ? loadDateTemplate(examDetails?.['Auth ExpDate']) : getData('Auth ExpDate') },
    ]
    const procedureData = [
        { title: 'Procedure', value: getData('Procedure') },
        { title: 'Modality', value: getData('Modality') },
        { title: 'Description', value: getData('Procedure Description') }
    ]
    const referringData = [
        { title: 'Referring Doctor', value: getData('Referring Doctor Name') },
        { title: 'NPI', value: getData('Referring Doctor Npi') },
        { title: 'Phone Number', value: getData('Referring Doctor Phone') }
    ]
    const facilityData = [
        { value: examDetails?.['Prov Name'], hasTitle: false },
        { value: examDetails?.['Prov Address'], hasTitle: false }
    ]

    const formatRADList = (radListObj) => {
        if (radListObj) {
            let radNames = [];
            radListObj.map((item) => {
                radNames.push(escapeHtml(item));
            })
            return radNames.sort((a, b) => a.localeCompare(b));
        }
    }

    const handleInputChange = (e) => {
        if (e?.target?.name === 'status') {
            setExamFormData({ ...examFormData, status: e?.target?.value })
        }
        else if (e?.target?.name === 'followUpDate') {
            setExamFormData({ ...examFormData, followUpDate: e?.value })
        }
        else if (e?.target?.name === 'exam-notes') {
            e?.target?.value?.length <= 500 && setExamFormData({ ...examFormData, examNotes: e?.target?.value });
        }
    }

    const calcTableHeight = () => {
        if (window.getComputedStyle(document.body).overflow === 'hidden') {
            const scrollbody = document.querySelectorAll('.top-legal-text');
            let coeBody = document.querySelectorAll('.coe');
            if (scrollbody && scrollbody.length>0 && coeBody && coeBody.length >0) {
                let scrollBodyRect = scrollbody[0].getBoundingClientRect();
                let coeBodyRect = coeBody[0].getBoundingClientRect();
                let height = (coeBodyRect.top - (scrollBodyRect.bottom * 1.5) / 1.45 );
                setDivHeight(height+'px')
            }
        }else{
            setDivHeight('300px')
        }  
    }

    window.addEventListener('resize', () => {
        calcTableHeight()
    });

    const renderExamDetails = (details, hasTitle = true) => {
        return (
            <>
                {
                    hasTitle ?
                        <p className="exam-details"><span className="exam-details-title">{details?.title}: </span>{details?.value}</p>
                        : <p className="exam-details">{details?.value}</p>
                }
            </>
        )
    }

    const saveAuthInfo = () => {
        setUpdateResetInProgress(true)
        const data = {
            "exam_id_list": [
                examDetails?.exam_id
            ],
            "exam_status": examFormData?.status,
            "followup_date": examFormData?.followUpDate ? moment(examFormData?.followUpDate).format('YYYY-MM-DD') : null
        }
        if (examFormData?.examNotes?.trim())
            data['exam_notes'] = examFormData?.examNotes
                    
        Auth.currentAuthenticatedUser()
            .then((userData) => {
                try {
                    ReferralService.submitAuthInfo(data).then((response) => {
                        if (response) {
                            displayToastMessage('success', 'Changes saved successfully!');
                            setLoading(true);
                        } else {
                            displayToastMessage('error', 'Failed to update auth info!');
                        }
                    }).catch((err) => {
                        displayToastMessage('error', 'Failed to update auth info!');
                    });
                } catch (error) {
                    displayToastMessage('error', 'Failed to update auth info!');
                }
            })
            .catch(() => {
                navigate('/signout')
            }).finally(() => {
                setUpdateResetInProgress(false)
            })
    }

    const enableSave = () => {        
        let followUpDateCondition = examFormData?.initialFollowupDate == null && examFormData?.followUpDate;

        if(examFormData?.initialFollowupDate && examFormData?.followUpDate){                        
            followUpDateCondition = !(moment(examFormData?.initialFollowupDate).isSame(moment(examFormData?.followUpDate)))
        }
        
        const statusCondition = examDetails?.['Prov Status'] !== examFormData?.status
        const notesAdded = examFormData?.examNotes && examDetails?.exam_notes !== examFormData?.examNotes
        return !(followUpDateCondition || statusCondition || notesAdded);
    }

    const hideDialog = () => {
        setActionDialog(false);               
        let requestData = {
            'exam_id': currentExam.exam_id,
            'action_sub_type': TEXT.CLOSE,
            'upload_event': {
                'status': TEXT.SUCCESS
            }
            }            
        ReferralService.auditUploadEvent(requestData); 
               
    };

    const studyUploadButtonClick = (rowData) => {
        if (rowData?.exam_id) {
            setCurrentExam(rowData)
            const initFormState = {
                accessionNumber: null,
                dicom: null,
                report: null,
                accept: null
            }            
            const currentQueue = getUploadQueue(rowData?.exam_id) 
            if(currentQueue){
                const { accessionNumber, fileInfo } = currentQueue 
                setUploadFormData({
                    accessionNumber: accessionNumber,
                    dicom: fileInfo[0].file,
                    report: fileInfo?.[1]?.file,
                    accept: true
                })
            }else setUploadFormData(initFormState)
            
            setActionDialog(true);
            let requestData = {
                "exam_id" : rowData?.exam_id,
                'action_sub_type': TEXT.VIEW,
                'upload_event': {
                  'status': TEXT.SUCCESS
                }
              }
             
            ReferralService.auditUploadEvent(requestData);
        }
    }

    const getTimeDifference = (dateVal) => {
        let difference = new Date() - new Date(dateVal)
        difference = difference / (1000*60);
        return difference >=TIME_INTERVAL_API_CALL_IN_MIN
    }
    
  
    const uploadButtonData = {
        'QUEUED': {'toolTip': 'Pending upload', icon: 'pi-clock', disable: false, label: 'Added to Queue'},
        'COMPLETED': {'toolTip': 'Study was successfully uploaded.', icon: 'pi-check', disable: true, label: 'Study Uploaded'}

    }  

    function getStatusById(id) {
        if (message?.[UploadStatusEnum.INPROGRESS]?.includes(id)) {
            return UploadStatusEnum.INPROGRESS;
        } else if (message?.[UploadStatusEnum.QUEUED]?.includes(id)) {
            return UploadStatusEnum.QUEUED;
        } else if (message?.[UploadStatusEnum.COMPLETED]?.includes(id)) {
            return UploadStatusEnum.COMPLETED;
        } else {
            return null; // In case the ID is not found in either array
        }
    }


    const uploadTemplate = () => {
        const currentQueue = getUploadQueue(examDetails?.exam_id);
        let status = getUploadStatus(examDetails, currentQueue)
        let difference = examDetails?.["Study Upload Lastupdated"] ? getTimeDifference(examDetails?.["Study Upload Lastupdated"]) : null
        if (getStatusById(examDetails?.exam_id))
            status = getStatusById(examDetails.exam_id)
        //if the exam is present in the broadcast message or if the status is uploading
        if (message?.[UploadStatusEnum.INPROGRESS]?.includes(examDetails?.exam_id) || 
        (status === UploadStatusEnum.INPROGRESS && !currentQueue) ) {
            return <div title="Upload In Progress."><Button
                className='exam-details-button'
                disabled={true}
                icon="pi pi-clock"
                onClick={() => studyUploadButtonClick(examDetails)} onKeyDown={() => { }}>Uploading Study
            </Button></div>
        } 
        else if (([UploadStatusEnum.INPROGRESS, UploadStatusEnum.STARTED].includes(status) && difference && !currentQueue) || status === UploadStatusEnum.FAILED) {
            let titleText = TEXT.FAILED_PLEASE_TRY_AGAIN
            return <div title={titleText}>
                <Button
                    icon='pi pi-exclamation-triangle'
                    className='exam-details-button'
                    aria-label='Upload-popup'
                    onClick={() => studyUploadButtonClick(examDetails)}
                >Upload Failed</Button>
            </div>
        }
        else if (uploadButtonData?.[status]) {
            if (currentQueue) {
                return (<div title={uploadButtonData?.[status].toolTip}><Button
                    icon={`pi ${uploadButtonData?.[status].icon}`}
                    className='exam-details-button-disabled'
                    aria-label='Upload-popup'
                    disabled={uploadButtonData?.[status].disable}
                    onClick={() => studyUploadButtonClick(examDetails)}
                >{uploadButtonData?.[status].label}</Button></div>)
            }
            else
                return (<div title={uploadButtonData?.[status].toolTip}><Button
                    icon={`pi ${uploadButtonData?.[status].icon}`}
                    className='exam-details-button-disabled'
                    aria-label='Upload-popup'
                    disabled={true}
                    onClick={() => studyUploadButtonClick(examDetails)}
                >{uploadButtonData?.[status].label}</Button></div>)
        }
        else if (status === UploadStatusEnum.INPROGRESS && currentQueue) {
            return (<Button
                className='exam-details-button'
                title="Study upload is in progress for this exam." onClick={() => studyUploadButtonClick(examDetails)} onKeyDown={() => { }}
                icon="pi pi-spin pi-spinner">Uploading Study
            </Button>)
        }
        else {
            return (
                <div title={TEXT.UPLOAD_STUDY_DICOM_REPORT}>
                    <Button
                        icon='pi pi-cloud-upload'
                        className='exam-details-button'
                        aria-label='Upload-popup'
                        onClick={() => studyUploadButtonClick(examDetails)}
                    >Upload Study</Button>
                </div>)

        }
    }
    return (
        <div>
            <Toast ref={toast} position='top-center' />
            {
                (!loading &&
                    <div>
                        <div className="top-legal-text">
                            <DisclaimerTop />
                        </div>
                        <div className="scrollable-div" ref={examDetailsDiv} style={{height: divHeight}}>
                            <div className="exam-details-info">
                                <div>
                                    <h1 className="m-0 pb-0 fw-700 h1-b">{examDetails?.['Patient Name'].trim()}</h1>
                                </div>
                                <div className="help-container">
                                    {apiConfig.upload_study && uploadTemplate()}
                                    <Button disabled={enableSave()}
                                        className={
                                            enableSave() ? "exam-details-button-disabled" : "exam-details-button"
                                        }
                                        data-testid="save-btn"
                                        icon={updateInProgress ? 'pi pi-spin pi-spinner' : ''}
                                        onClick={() => { saveAuthInfo() }}
                                    >Save Changes</Button>
                                    <Button className="exam-details-button" data-testid="back-btn"
                                        onClick={() => {
                                            navigate("/");
                                            setSelectedTab('referrallist')
                                        }}
                                    >Back to List</Button>
                                </div>
                            </div>
                            <h3 className='pb-1 fw-700 h3-2 mb-6 mt-0'>Date of Birth<span className="fw-200">: {examDetails?.['DOB'] ? loadDateTemplate(examDetails?.['DOB']) : ''}</span></h3>
                            <p className="exam-details">This patient has been approved by their carrier for the following procedure: </p>
                            <br />
                            <div className="ui two column grid">
                                <div className="column">
                                    <div>
                                        {
                                            authorizationData?.map(details => renderExamDetails(details))
                                        }
                                        <br />
                                        {
                                            procedureData?.map((details) => renderExamDetails(details))
                                        }
                                        <br />
                                        {
                                            referringData?.map((details) => renderExamDetails(details))
                                        }
                                        <br />
                                        <h5 className='m-0 pb-1 fw-700 h5-2 mt-0'>Designated Facility: </h5>
                                        {
                                             (facilityData?.[0]?.value || facilityData?.[1]?.value) ? facilityData?.map((details) => renderExamDetails(details, false)) : DEFAULT_MESSAGE
                                        }
                                        <br />
                                    </div>
                                </div>
                                <div className="column">                                    
                                    <div className="row display-flex">
                                        <div className="column div-left">
                                            <span className="exam-details-title">Exam Status:</span>
                                        </div>
                                        <div className="column div-right">
                                            <Dropdown
                                                name='status'
                                                data-testid="status-dropdown"
                                                value={examFormData?.status}
                                                options={examStatuses} optionLabel="displayText" optionValue="value"
                                                className="ta-left w-205 exam-details-status"
                                                title="Please mark the list item as Complete once the exam has been interpreted by a radiologist."
                                                onChange={handleInputChange}
                                            />
                                        </div>                                        
                                    </div>
                                    <div className="row display-flex">
                                        <div className="column div-left">
                                            <span className="exam-details-title">Follow-up Date:</span>
                                        </div>
                                        <div className="column div-right">
                                            <Calendar
                                                dateFormat="MM dd, yy"
                                                value={examFormData?.followUpDate}
                                                onChange={handleInputChange}
                                                name='followUpDate'
                                                showIcon placeholder="Follow-up Date"
                                                data-testid='calender-follow-up-date'
                                                className='exam-details-follow-up-date'
                                            />
                                        </div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="column">
                                            <span className="mr-10 mt-10 exam-details-title">Provider Notes:</span>
                                        </div>
                                        <div className="column">
                                            <InputTextarea 
                                                value={examFormData?.examNotes}
                                                autoResize={true}
                                                onChange={handleInputChange}
                                                name='exam-notes'
                                            />
                                        </div>
                                        <div className="txt-ar-notes-char-length">
                                            <span>{examFormData?.examNotes?.trim().length} / 500 characters </span> 
                                        </div>                                 
                                    </div>
                                    <div className="pt-20">
                                        <h4>Designated Radiologists for Interpretation</h4>
                                        <ul>
                                            {
                                                examDetails?.['Rad List'] && formatRADList(examDetails['Rad List']).map(rad => (
                                                    <li>{rad}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row coe pl-5 pr-5">
                            <span>
                                <p>
                                    <DisclaimerBottom />
                                </p>
                            </span>
                        </div>
                        <Footer />
                    </div>
                )
            }
            <Dialog visible={actionDialog} header={ReferralActions.StudyUpload} modal className={`p-fluid dialog-div-upload`} onHide={hideDialog} closable={true}>
                <UploadForm formState={uploadFormData} examDetails={examDetails} setActionDialog={setActionDialog} displayToastMessage={displayToastMessage}/>
            </Dialog>
        </div>
    )
}

ExamDetails.propTypes = {
    apiConfig: PropTypes.object.isRequired,
    setSelectedTab: PropTypes.func
};
export default ExamDetails