import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
const BroadcastChannelContext = createContext();

export const useBroadcastChannel = () => {
    return useContext(BroadcastChannelContext);
};

export const BroadcastChannelProvider = ({ children }) => {
    const [channel, setChannel] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        // Create the BroadcastChannel and listen for messages
        const bc = new BroadcastChannel('app_channel');

        // Set up a listener to handle messages from other tabs
        bc.onmessage = (event) => {
            setMessage(event.data);
        };

        // Store the channel to send messages
        setChannel(bc);

        // Clean up when the component unmounts
        return () => {
            bc.close();
        };
    }, []);

    const sendMessage = (data) => {
        if (channel) {
            channel.postMessage(data);
        }
    };

    const contextValue = useMemo(() => ({
        message,
        sendMessage,
    }), [message, sendMessage]);
    return (
        <BroadcastChannelContext.Provider value={contextValue}>
            {children}
        </BroadcastChannelContext.Provider>
    );
};
BroadcastChannelProvider.propTypes = {
    children: PropTypes.object.isRequired,
};