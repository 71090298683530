import React, { useState } from 'react';
import { ReferralService } from './referral_list/ReferralService';
import PropTypes from 'prop-types';

function Logout({ uploadingExam, isUploading, signoutUser, apiConfig }) {

    const [showDialog, setShowDialog] = useState(false)
    const verifyUpload = () => {
        if (isUploading && uploadingExam) {
            setShowDialog(true)
        } else {
            setShowDialog(false)
            signoutUser(apiConfig)
        }
    }
    const staySignIn = () => {
        setShowDialog(false)
    }
    const signOut = () => {
        if (uploadingExam) {
            let requestData = {
                'exam_id': uploadingExam,
                'action_sub_type': 'FORCE_STOP',
                'upload_event': {
                    'message': 'User force logged out while study upload was in progress',
                    'exam_id': uploadingExam
                }
            }
            ReferralService.auditUploadEvent(requestData).then((res) => {
                signoutUser()
            });
        }
    }

    return (
        <>{
            <><button className="logout-button" onClick={verifyUpload} data-testid="logout">Log Out</button>

                <div>
                    <div className="row" style={{ display: showDialog ? 'flex' : 'none' }}>
                        <div className='modal' style={{ display: showDialog ? 'flex' : 'none' }}>
                            <div className="modal-content">
                                <h3>Study Upload In progress!</h3>
                                <hr></hr>
                                <p>Do you still want to log out?</p>
                                <p>To continue upload, click on <strong>Stay Signed In</strong>.</p>
                                <div className="btn-container">
                                    <button onClick={staySignIn} data-testid="stay-signedin">Stay Signed In</button>
                                    <button type="button" onClick={signOut} data-testid="signout">Sign out</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>

    );
}
Logout.propTypes = {
    apiConfig: PropTypes.any,
    uploadingExam:  PropTypes.string,
    isUploading:  PropTypes.bool,
    signoutUser: PropTypes.func
};
export default Logout;

