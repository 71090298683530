import Logout from "./Logout";
import React from 'react';
import { Menubar } from 'primereact/menubar';
import '../index.css';
import PropTypes from 'prop-types';

function Header(props) {
    const menuItems = []
    const start = <a href="https://www.coverahealth.com/"><img src="/img/logo-light.png" alt="Covera Health" style={{ objectFit: "contain", padding: "15px" }} className="ui small image" /></a>;
    const end = props.showLogout === true ? <div className="right menu"><Logout uploadingExam={props.uploadingExam} isUploading={props.isUploading} signoutUser={props.signoutUser} apiConfig={props.apiConfig} /> </div>: <div></div>
    
    if (props.userData 
        && props.userData.signInUserSession 
        && props.userData.signInUserSession.accessToken 
        && props.userData.signInUserSession.accessToken.payload) {
        menuItems.push(  {
                label: 'Referral List',
                className: props.selectedMenu === undefined || props.selectedMenu === 'referrallist'? "p-menuitem-active" : "",
                command: ()=>{
                    props.changeTab('referrallist');
               }
        })    
        const groups = props.userData.signInUserSession.accessToken.payload['cognito:groups']
        const isPartOfAdminGroup = groups && groups.includes(props.apiConfig.admin_group_name)
        if (isPartOfAdminGroup) {
            menuItems.push({
                label: 'Manage Users',
                className: props.selectedMenu === 'manageusers' ? "p-menuitem-active" : "",
                command: ()=>{
                    props.changeTab('manageusers');
                }
                // template: (item, options) => {
                //     return (                    

                //         <a href="/manageusers" role="menuitem" class="p-menuitem-link" aria-haspopup="false" data-pc-section="action">
                //             <span class="p-menuitem-text" data-pc-section="label">Manage Users</span>
                //         </a>

                //     );
                // } 
            })
        }
    }
    return (
        <Menubar model={menuItems} start={start} end={end} />
    )
    
}
Header.propTypes = {
    selectedMenu: PropTypes.string,
    uploadingExam:  PropTypes.string,
    isUploading:  PropTypes.bool,
    signoutUser: PropTypes.func
};
export default Header;

