import Header from "./Header";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import ReferralList from "./referral_list/ReferralList"
import UserList from "./manage_users/UserList";
import { Dialog } from 'primereact/dialog';
import UserAgreement from '../components/user_agreement/UserAgreement';
import { Menubar } from 'primereact/menubar';
import { useNavigate, useParams } from "react-router-dom";
import HelpView from './help/HelpView';
import ExamDetails from "./referral_list/ExamDetails";
import { useUploadQueueContext } from "./referral_list/upload/UploadQueueContextApi";

function AuthorizationView(props) {
    const { exam_id } = useParams();
    const propsData = getPropsData();
    const TEXT = {
        REFERRAL_LIST: 'referrallist',
        MANAGE_USERS: 'manageusers',
        HELP: 'help',
        EXAM_DETAILS: 'examdetails'
    }
    const [user, setUser] = useState(propsData.user);
    const [state, setState] = useState('ACTIVE');
    const [selectedTab, setSelectedTab] = useState(exam_id ? TEXT.EXAM_DETAILS : TEXT.REFERRAL_LIST);
    const [amplifyStatus, setAmplifyStatus] = useState(propsData.amplifyStatus);
    const [remaining, setRemaining] = useState(0);
    const navigate = useNavigate();
    const { isUploading, getCurrentQueueByStatus,  evaporate }  = useUploadQueueContext()
    const killEvaporate = async () => {
        if (evaporate?.filesInProcess?.length > 0){
          await evaporate.cancel();
        }
      };


    const onIdle = () => {
        if (!isUploading){
            setState('SIGNINGOUT')
            sessionTimeout();
        }
    }

    const onActive = () => { setState('ACTIVE'); }
    const onPrompt = () => { 
        if (!isUploading) {
            setState('PROMPT');
        }
     }
    const staySignIn = () => { setState('ACTIVE'); activate(); }
    const signOut = () => { setState('SIGNINGOUT'); signoutUser(); }
    const timeout = amplifyStatus.apiConfig.sessionTimeout;
    const promptBeforeIdle = amplifyStatus.apiConfig.promptBeforeIdle;
    let userAgreement = null;
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        if (state==='PROMPT'){
            const interval = setInterval(() => {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }, 500)
            return () => {
                clearInterval(interval)
            }
        }
    });

    const getUserAgreement = () => {
        if (propsData == null || propsData.setUserAgreement == null) {
            return null;
        }

        return propsData.userAgreement;
    }

    const setUserAgreement = () => {
        if (propsData == null || propsData.setUserAgreement == null) {
            return null;
        }

        return propsData.setUserAgreement;
    }

    if (getUserAgreement) {
        userAgreement = getUserAgreement()
    }

    function getPropsData() {
        if (props) {
            return props;
        } else {
            return null;
        }
    }

    async function sessionTimeout() {
        logoutUser(true);
    }

    async function logoutUser(sessionTimeout) {
        await killEvaporate()
        try {
            await propsData.signOut(sessionTimeout);
            setUser(null);
            setAmplifyStatus({
                status: 'Logout',
                apiConfig: undefined
            })
            navigate("/signout")
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    async function signoutUser() {
        logoutUser(false);
    }

    const hideDialog = () => {
        if (setUserAgreement && userAgreement) {
            propsData.setUserAgreement({
                ...userAgreement,
                show: false,
            });
        }
    };

    function canShowUserAgreement() {
        if (userAgreement?.show) {
            return userAgreement.show;
        } else {
            return false;
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isUploading) {
                e.preventDefault()
                const confirmMessage = "Study upload is in progress, Are you sure you want to refresh the page?"
                e.returnValue = confirmMessage;
                return confirmMessage;
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }

    }, [isUploading])

    const start = <a href="https://www.coverahealth.com/"><img src="img/logo-light.png" alt="Covera Health" style={{ objectFit: "contain", padding: "15px" }} className="ui small image" /></a>;
    return (
        user && amplifyStatus.apiConfig ? (
            <div>
                <Dialog draggable={false} visible={canShowUserAgreement()} header="Terms And Conditions" modal className="p-fluid tc-dialog-div" onHide={hideDialog}>
                    <UserAgreement hideDialog={hideDialog} userData={user} amplifyStatus={amplifyStatus} userAgreement={userAgreement} />
                </Dialog>
                {
                    !userAgreement.show ? (
                        <div>
                            <Header isUploading={isUploading} uploadingExam={getCurrentQueueByStatus()?.INPROGRESS?.[0]} showLogout={true} userData={user} signoutUser={signoutUser}
                                apiConfig={amplifyStatus.apiConfig} selectedMenu={selectedTab}
                                changeTab={setSelectedTab}
                            ></Header>
                            <div className="row" style={{ display: (state === 'PROMPT') ? 'flex' : 'none' }}>
                                <div className='modal' style={{ display: (state === 'PROMPT') ? 'flex' : 'none' }}>
                                    <div className="modal-content">
                                        <h3>Session Timeout</h3>
                                        <hr></hr>
                                        <p>Your session is about to expire. You will be automatically signed out in {remaining} seconds</p>
                                        <p>To continue your session, click on <strong>Stay Signed In</strong>.</p>
                                        <div className="btn-container">
                                            <button onClick={staySignIn} data-testid="stay-signedin">Stay Signed In</button>
                                            <button type="button" onClick={signOut} data-testid="signout">Sign out</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(selectedTab === TEXT.REFERRAL_LIST) && <ReferralList
                                state={state} apiConfig={amplifyStatus.apiConfig} setSelectedTab={setSelectedTab} user={user}></ReferralList>
                            }
                            {(selectedTab === TEXT.MANAGE_USERS) && <UserList apiConfig={amplifyStatus.apiConfig} setSelectedTab={setSelectedTab} user={user}></UserList>}
                            {(selectedTab === TEXT.EXAM_DETAILS) && <ExamDetails apiConfig={amplifyStatus.apiConfig} setSelectedTab={setSelectedTab} />}
                            {(selectedTab === TEXT.HELP) && <HelpView user={user} setSelectedTab={setSelectedTab} />}
                        </div>) : <div>
                        <Menubar start={start} />
                    </div>
                }
            </div >
        ) : <div>
        </div>
    );
}

export default AuthorizationView;

