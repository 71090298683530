import { Amplify, Auth } from 'aws-amplify';
import { initConfig } from './utils/api';

export function getCurrentAuthenticatedUser() {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then(userData => resolve(userData))
            .catch(() => reject("Not signed in"));
    })
}

export const intAmplify = (callback) => {
    return new Promise((resolve, reject) => {
        try {
                initConfig().then((awsConfig) => {
                    Amplify.configure(awsConfig);
                    let sessionTimeout = awsConfig.sessionTimeout ?  awsConfig.sessionTimeout:120;
                    let promptBeforeIdle = awsConfig.promptBeforeIdle ?  awsConfig.promptBeforeIdle:1 //Default to 1 mins
                    resolve({
                        status: 'INITIALIZED',
                        apiConfig: { 
                            "api": awsConfig.api,
                            "customer": awsConfig.customer,
                            "isAmbra": awsConfig.isAmbra,
                            "sessionTimeout": sessionTimeout * 60 * 1000, //Default to 120 mins
                            "promptBeforeIdle": promptBeforeIdle * 60 * 1000,
                            "admin_group_name": awsConfig.admin_group_name,
                            "authenticationFlowType": awsConfig.authenticationFlowType,
                            "upload_study" : awsConfig.upload_study,
                            "report_in_dicom": awsConfig.report_in_dicom,
                            "max_retry_backoff_secs": awsConfig?.study_upload_max_retry_backoff_secs,
                            "max_retry_backoff_power": awsConfig?.study_upload_max_retry_backoff_power,
                        }
                    });
                }).catch((error) => {
                    reject({
                        status: 'ERROR'
                    });
                });
            
        } catch (error) {
            callback({
                status: 'ERROR'
            })
        }
    });
}
