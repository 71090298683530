import * as React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import MainView from './components/MainView';
import SignIn from './components/SignIn';
import SignOutView from './components/Signout';
import SSOView from './components/SSOView';
import ResetPasswordForm from './components/manage_users/ResetPasswordForm';
import HelpView from './components/help/HelpView';
import {ForgotPassword, ExpirePassword} from './components/forgot_password/ForgotPassword';
import NotFound from './components/NotFound';
import ExamDetails from './components/referral_list/ExamDetails';
import { BroadcastChannelProvider } from './context/BroadcastChannelContext';

/*Introducing a new route may required a new entry in qcc-auth-portal\ngnix-config\nginx.conf*/
const App = () => (
  <BroadcastChannelProvider>
  <Router>
    <Routes>
      	<Route exact path="/signin" element={<SignIn />} />
        <Route path="/" element={<MainView />}>
          <Route path="/exams/:exam_id" element = {<ExamDetails/>}/>
        </Route>     
  
        <Route path="/signout" element={<SignOutView />} />
        <Route path="/login" element={<SSOView />} />
        <Route path="/changepassword" element={<ResetPasswordForm />} />
        <Route path="/help" element= {<HelpView/>}/>
        <Route path="/forgotpassword" element = {<ForgotPassword/>}/>
        <Route path="/resetpassword" element = {<ExpirePassword/>}/>
        
        <Route  path="*" element={<NotFound/>} />
    </Routes>
  </Router>
  </BroadcastChannelProvider>
);

export default App;