import axios from 'axios';
import { Auth } from "aws-amplify";
import { API_ENDPOINTS, API_PREFIX, public_endpoints, exclude_customer_endpoints } from './UrlUtils';


/**
* Creating Global API instance for making api calls using Axios. 
*/
const api = axios.create({
  responseType: 'json',
});

/**
 * Api interceptors.
 * It handles the global headers/tokens for making an api request.
 * @returns token
 */
api.interceptors.request.use(  
  async config => {
    let authHeaders = await getAuthHeader();
    const csrf_token = await getCSRFToken();
    config.headers = {
      'X-CSRF-TOKEN' : csrf_token, ...authHeaders
    };    
    config.mode = 'CORS';
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

/**
 * Get Authorization Header for making api request.
 * @returns token
 */
async function getAuthHeader() {
  let authHeader = null;  
  await Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken();
    let idToken = res.getIdToken();
    authHeader = {
      'Authorization': "Bearer  " + accessToken.getJwtToken(),
      'IdToken': idToken.getJwtToken()
    };
  });
  return authHeader;
}

export default api;
let apiConfig;

export function initConfig() {
  return new Promise((resolve, reject) => {
    if (apiConfig === undefined) {
      axios.get('/config').then((res) => {
        apiConfig = res.data;
        resolve(apiConfig);
      }).catch((error) => {
        reject(error);
      });
    }else{
      resolve(apiConfig)
    }
  });

}
/**
 * Constructing endpoint based on the input url.
 * @param {*} url 
 * @param {*} apiConfig 
 * @returns 
 */
async function constructEndpoint(url, checkAwsConfig = true) {
  /**
   * Read the /config and find the config values.
   * Url to be constructed based on the awsConfig
   */
  
  if (checkAwsConfig && apiConfig === undefined) {
    await initConfig()
  }

  let api_host = "";
  let api_customer = "";
  let endpoint = '';

  if (apiConfig) {
    api_host = apiConfig.api ? apiConfig.api : "";
    api_customer = apiConfig.customer ? ("/" + apiConfig.customer) : "";
  }

  if (public_endpoints.includes(url))
    endpoint = API_PREFIX.PUBLIC;

  endpoint = endpoint + API_PREFIX.VERSION;
  if (api_customer && exclude_customer_endpoints.includes(url))
    api_customer = ""
  return api_host + endpoint + api_customer + url;
}

/**
 * Global api calls for GET method
 * @param {url} api endpoint 
 * @returns response data
 */
export async function invokeGetApi(url) {
  let endpoint = await constructEndpoint(url)
  let response = null;
  await api.get(endpoint).then((apiResponse) => {
    response = apiResponse?.data;
  });
  return response;
}

/**
 * Global API calls for POST method
 * Using axios instance posting the request to Api along with Request Headers.
 * @param {url} api endpoint 
 * @param {*} requestData 
 * @returns 
 */
export async function invokePostApi(url, requestData) {
  let endpoint = await constructEndpoint(url)
  try{
    const { data } = await api.post(endpoint, requestData)
    return data;
  }catch(error){
    let message = error.response.data ? error.response.data.message : 'Error while performing the request.';
    return { data: null, message: message };
  }
}

/**
 * Global api POST method for excluding header if necessary.
 * @param {*} url 
 * @param {*} requestData 
 * @param {*} checkAwsConfig 
 * @returns 
 */
export async function invokePostApiWithoutAuthHeader(url, requestData, checkAwsConfig = true) {
  let endpoint = await constructEndpoint(url, checkAwsConfig);
  const csrf_token = await getCSRFToken();
  try
  {
    const response = await axios.post(endpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN' : csrf_token
      }
    });
    return response;
  }catch(error){
    return error.response
  }
}

const getCSRFToken = async () => {
  try{
    const { headers } = await axios.get(API_ENDPOINTS.CSRF);
    return headers ? headers['x-csrftoken']  : ""
  }catch(err){
    console.log(err);
    return undefined
  }
};

export async function getAuthCsrfToken(){
  let headers = null;
  headers = await getAuthHeader();
  let csrf_token = await getCSRFToken();
  return csrf_token ? {
    'X-CSRF-TOKEN' : csrf_token, ...headers
  } : headers;
}
